<template>
  <div class="search">
    <div style="font-size: 18px;color: #000000;padding-top: 44px;margin-left: 16px;font-weight: 600;">{{ parkinglotInfo.parkingLotName }}</div>
    <div style="font-size: 14px;color: #000000;padding-top: 12px;margin-left: 16px;">累计欠费订单：<span style="color: #3A8AFF;">{{ parkinglotInfo.orderCount }}</span></div>
    <div style="
      margin: 25px 16px;">
      <div v-for="(item, index) in orderList" :key="index"
           :style="{marginTop: index==0?'0px':'15px',}"
           style="background: #FFFFFF; border-radius: 6px;padding: 11px 12px;display: flex;align-items: center;">
        <div style="width: 66px;display: flex;align-items: center;justify-content: center;">
          <input type="checkbox" aria-hidden="false"
                 @change="inputChanged()"
                 style="width: 17.5px;height: 17.5px;" v-model="item.checked">
        </div>
        <div style="flex: 1;">
          <div style="display: flex;align-items: center;justify-content: space-between;">
            <div style="color: #393939;font-size: 14px;">{{item.plateNumber}}</div>
            <div v-if="item.orderFlag=='0'" style="width: 52px;height: 18px;background: rgba(6, 189, 156, 0.08);color: #06BD9C;font-size: 10px;display: flex;align-items: center;justify-content:center;">当前订单</div>
          </div>
          <div style="width: 100%;height: 1px;background: #E6EBF0;margin: 12px auto;"></div>
          <div style="color: #000000;font-size: 12px;">停车时长：{{ item.parkingTime }}</div>
          <div style="color: #000000;font-size: 12px;margin-top: 12px;">驶入时间：{{ item.driveInTime }}</div>
          <div style="color: #000000;font-size: 12px;margin-top: 12px;">驶出时间：{{ item.driveOutTime }}</div>
          <div style="color: #777777;font-size: 12px;margin-top: 24px;">金额 <span style="color: #FE7134;">￥<span style="font-size: 16px;">{{ item.payableAmount }}</span> </span></div>
        </div>
      </div>
    </div>
    <div style="position: fixed;
				left: 0;
				bottom: 0;
				width: 100%;
				height: 54px;
				background: #fff;
				border-top: 1rpx solid #E6E6E6;
				display: flex;
				align-items: center;
				justify-content: space-between;">
      <input type="checkbox" aria-hidden="false"
             v-model="allChecked"
             @change="allChanged()"
             style="width: 17.5px;height: 17.5px;margin-left: 30px;">
      <div style="font-size: 12px;flex:1;">
        <div>全选</div>
        <div style="margin-top: 4px;">已选 {{orderList.filter(order => order.checked).length}}｜总金额：¥ {{ totalAmount.toFixed(2) }}</div>
      </div>
      <div style="width: 90px;height: 38px;background: #3A8AFF;
        border-radius: 4px;font-size: 14px;display: flex;align-items: center;
        justify-content: center;color: #FFFFFF;margin-right: 30px;"
           @click="toPay">
        去支付
      </div>
    </div>
  </div>
</template>
<script>
import {
  getParkingOrderPayList,
} from "@/api/system";

export default {
  data() {
    return {
      licensePlateDoor: false,
      parkinglotInfo: {},
      orderList: [],
      parkingOrderInfoId: '',
      activeIndex: 0,
      plateColor:'02',
      totalAmount: 0,
      allChecked: true,
      licensePlateUnit: [],
      // 支付来源 0 app 1 pda(路内) 2 web 3 道闸码(路外-车道码) 4 场内码(路外-场内码)
      source: '4',
      columns: [//省缩写选择
        '京', '津','冀','晋','蒙','辽','吉','黑','沪','苏',
        '浙','皖','闽','赣','鲁','豫','鄂','湘','粤',
        '琼','桂','甘','陕','新','青','宁','渝',
        '川','贵','云','藏','领','使',
      ],
      numberColumns: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0','Q', 'W', 'E',
        'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'Z',
        'X', 'C', 'V', 'B', 'N', 'M', '港','澳','学','警'
      ],
    }
  },
  computed:{
  },
  components:{
    // Numplate,
    // WechatOrder,
  },
  mounted() {
    this.source = this.$route.query.source;
    this.parkingOrderInfoId = this.$route.query.parkingOrderInfoId;
    this.getParkingOrderPayList()
  },
  methods: {
    getTotalAmount(){
      this.totalAmount = 0
      this.orderList.forEach((order) => {
        if(order.checked){
          this.totalAmount+=Number(order.payableAmount)
        }
      })
    },
    allChanged(){
      this.orderList.forEach(order => order.checked = this.allChecked)
      this.getTotalAmount()
    },
    inputChanged(){
      this.allChecked = this.orderList.filter(order => order.checked===false).length<=0
      this.getTotalAmount()
    },
    getParkingOrderPayList(){
      getParkingOrderPayList(this.parkingOrderInfoId, this.source)
          .then(res =>{
            if (res && res.code === 30) {
              if(res.returnObject.orderList.length==0){
                this.$message.warning('未查询到订单信息')
                return
              }else {
                this.parkinglotInfo = res.returnObject
                this.orderList = res.returnObject.orderList
                this.orderList.forEach((order) => order.checked = true)
                this.getTotalAmount()
                // this.$router.push("/payment?parkingOrderIntoId="+res.returnObject.parkingOrderIntoId+"&source="+this.source);
              }
              console.log(res.returnObject)
            }
          })
    },
    toPay(){
      if(this.orderList.filter(order => order.checked).length==0){
        this.$message.warning('请先选择一个订单')
        return
      }
      var orderIds = this.orderList.filter(order => order.checked).map(order => order.orderId).join(',')
      this.$router.push("/payment?parkingOrderIntoId="+orderIds+
          "&source="+this.source+
          '&amount='+this.totalAmount+
          '&parkingLotName='+this.parkinglotInfo.parkingLotName+
          '&wxAppId='+this.parkinglotInfo.wxAppId+
          '&zfbAppId='+this.parkinglotInfo.zfbAppId

      );
    },
    changePlateColor(color){
      this.plateColor = color;
    },
    pickOn(value) {
      this.licensePlateDoor = true;
      if (this.licensePlateUnit.length <= 7) {
        this.licensePlateUnit.push(value)
      }
    },
    delCarNo() {
      this.licensePlateUnit.pop();
    },
    confirm() {
      if(this.licensePlateUnit.length >= 7) {
        console.log("车牌是："+this.licensePlateUnit.join(''));
        this.licensePlateDoor = false;
      }
    },
  }
}
</script>


<style scoped>
.search{
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #F7F8FA;
  background-image: url("../../assets/images/search_bg.png");
  background-repeat: repeat-x;
  background-size: 100vw;
}

input:focus {
  border-bottom: 3px solid #fff;
  transition: all 0.5s;
}
</style>
